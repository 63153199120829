const apiPrefix = `${process.env.REACT_APP_API_URL}/v1/api`;
const config = {
  API_URL: process.env.REACT_APP_API_URL,
  DIGITAL_SHDAE_CARD_IMG_URL: `${process.env.REACT_APP_API_URL}/digitalShadeCard`,
  EXPERIENCE_CENTER_IMG_URL: `${process.env.REACT_APP_API_URL}/experienceCenter`,
  INVESTOR_RELATION_IMG_URL: `${process.env.REACT_APP_API_URL}/investorRelation`,
  NEWS_AND_EVENT_IMG_URL: `${process.env.REACT_APP_API_URL}/newsAndEvent`,
  apiPrefix,
};

export default config;
